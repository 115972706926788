<template>
  <div>
    <h2>Halaman Dashboard</h2>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  components: {
  },
  data() {
    return {
      currentUser: getUser(),
      dashboard: false,
    };
  },
  methods: {
    
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
};
</script>
